/*--------------------------------------------------------------
  14. Team
----------------------------------------------------------------*/
.cs_team.cs_style_1 {
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;
  .cs_team_member-name {
    line-height: 1.35em;
  }
  .cs_team_info {
    margin-top: -100px;
    background-color: $primary;
  }
  .cs_social_btns {
    left: 50%;
    transform: translateX(-50%) scale(0);
    bottom: 0px;
    opacity: 0;
    width: 100%;
    justify-content: center;
    a {
      background-color: $secondary;
      &:hover {
        background-color: $accent;
      }
    }
  }
  &:hover {
    .cs_team_info {
      background-color: $accent;
    }
    .cs_social_btns {
      transform: translateX(-50%) scale(1);
      bottom: 30px;
      opacity: 1;
    }
  }
}
